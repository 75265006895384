import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton } from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CustomButton, DatePickerCustom } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import MeetingNoteApi from "../../../system/api/MeetingNoteApi";
import UserApi from "../../../system/api/UserApi";
import FileApi from "../../../system/api/fileApi";
import { useUserState } from "../../../system/context/UserContext";
import { MeetingNoteDetail } from "../../../system/types/MeetingNote";
import { InitMeetingNoteDetail } from "../../../system/types/initObject";
import {
  Ilocation,
  MeetingNoteRoutes,
  WorkUser,
} from "../../../system/types/type";
import CrossEditor from "../../Common/Editor/CrossEditor";
import SelectMultiEmployee from "../../Common/SelectMultiEmployee";
import SubTitleDiv from "../../Common/SubTitleDiv";
import TitleDiv from "../../Common/TitleDiv";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

interface Params {
  id: string;
}

export default function MeetingEdit() {
  const { id } = useParams<Params>();
  const meetingId = parseInt(id);
  const history = useHistory();
  const location = useLocation<Ilocation>();
  const parent = location.state;
  const user = useUserState();
  const refContent = createRef<CrossEditor>();
  const [data, setData] = React.useState<MeetingNoteDetail>(
    InitMeetingNoteDetail
  );
  const [fileList, setFileList] = React.useState<File[]>([]);
  const [nameList, setNameList] = React.useState<string[]>([]);
  const [started, setStarted] = useState<boolean>(false);
  const params = {
    Width: "100%",
    Height: 800,
  };
  const loadingDispatch = useLoadingDispatch();
  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  useEffect(() => {
    if (meetingId) {
      MeetingNoteApi.getMeetingNoteDetail(meetingId)
        .then((res) => {
          setData({ ...res.data, setInfo: true });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      UserApi.GetAIUsers(user.name)
        .then((res) => {
          setData({ ...data, participants: res.data, setInfo: true });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId, user.name]);

  const saveComplete = () => {
    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }

    // validation
    let flag = true;

    if (data.participants.length < 1) {
      alert("1명 이상의 참여자가 지정되어야 합니다.");
      flag = false;
      return;
    } else if (data.title === "" || data.meetingDate === "") {
      alert("모든 입력값을 채워주세요.");
      flag = false;
      return;
    }

    // 최종 저장
    if (flag && refContent.current) {
      const editor = refContent.current.editorRef;
      let promise: Promise<any>;
      loadingDispatch({ type: "LOADING" });
      if (fileList.length > 0) {
        const formData = new FormData();
        Array.from(fileList).forEach((el) => {
          formData.append("uploadFiles", el);
        });
        FileApi.upload(formData)
          .then((file) => {
            if (data.id === 0) {
              promise = MeetingNoteApi.createMeetingNoteList({
                ...data,
                attachmentFiles: data.attachmentFiles.concat(file),
                content: editor.GetBodyValue() || "",
                parentId: parent.parentId,
              });
            } else {
              promise = MeetingNoteApi.updateMeetingNote({
                ...data,
                attachmentFiles: data.attachmentFiles.concat(file),
                content: editor.GetBodyValue() || "",
              });
            }
            promise
              .then((res) => {
                setData(res.data);
                alert("정상적으로 등록되었습니다.");
                history.push({
                  pathname: `${MeetingNoteRoutes.root}/detail/${res.data.id}`,
                  state: parent,
                });
              })
              .catch((err) => {
                let msg = ErrorHandler(err);
                alert(msg);
              });
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            loadingDispatch({ type: "COMPLETE" });
          });
      } else {
        if (data.id === 0) {
          promise = MeetingNoteApi.createMeetingNoteList({
            ...data,
            content: editor.GetBodyValue() || "",
            parentId: parent.parentId,
          });
        } else {
          promise = MeetingNoteApi.updateMeetingNote({
            ...data,
            content: editor.GetBodyValue() || "",
          });
        }
        promise
          .then((res) => {
            setData(res.data);
            alert("정상적으로 등록되었습니다.");
            history.push({
              pathname: `${MeetingNoteRoutes.root}/detail/${res.data.id}`,
              state: parent,
            });
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            loadingDispatch({ type: "COMPLETE" });
          });
      }
    }
  };

  let datePick: any;

  const onChange = (args: any) => {
    if (args.target.name === "meetingDate") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: moment(
          args.target.value
        ).format("YYYY-MM-DD"),
      });
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };

  const onChangeEmp = (userList: WorkUser[]) => {
    if (userList) {
      setData({ ...data, participants: userList });
    }
  };

  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      // 미리보기 기능
      const targetFiles = Array.from(event.target.files);
      const totalSize = targetFiles.reduce((acc, file) => acc + file.size, 0);
      const maxSize = 1024 * 1024 * 1024;
      if (totalSize > maxSize) {
        alert("업로드 파일은 1GB를 넘을 수 없습니다.");
        return;
      }
      setFileList(targetFiles);
      const selectedFile: string[] = [];
      targetFiles.map((file) => {
        return selectedFile.push(file.name);
      });
      setNameList(selectedFile);
    }
  };

  const deleteFile = (e: any) => {
    const fileArr = nameList.filter((x, idx) => idx !== e);
    const uploadArr = fileList.filter((x, idx) => idx !== e);
    setNameList([...fileArr]);
    setFileList([...uploadArr]);
  };

  const deleteExistFile = (idx: number) => {
    if (
      window.confirm(
        "파일을 정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다."
      )
    ) {
      setData({
        ...data,
        attachmentFiles: data.attachmentFiles.filter((x, i) => i !== idx),
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="미팅노트 등록"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <SubTitleDiv title={parent.parentTitle}></SubTitleDiv>
      </Grid>
      <Grid item xs={12}>
        미팅주제
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          placeholder="미팅주제를 입력해 주세요."
          style={{ height: "48px" }}
          cssClass="e-outline"
          value={data.title}
          className="e-input"
          type="text"
          name="title"
          onChange={onChange}
        />
        {data.title.length === 0 ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 미팅주제를
            입력해주세요.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        참여자
      </Grid>
      <Grid item xs={12}>
        <SelectMultiEmployee
          list={data.participants}
          onChange={onChangeEmp}
        ></SelectMultiEmployee>
        {data.participants.length < 1 ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 참여자가 지정되어
            있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        미팅일자
      </Grid>
      <Grid item xs={12}>
        <DatePickerCustom>
          <DatePickerComponent
            placeholder="미팅일자를 입력해 주세요."
            name="meetingDate"
            format="yyyy-MM-dd"
            cssClass="e-outline e-custom"
            style={{ height: "48px" }}
            value={new Date(data.meetingDate)}
            onChange={onChange}
            focus={(e) => datePick.show()}
            ref={(scope) => {
              datePick = scope;
            }}
            width="30%"
          />
        </DatePickerCustom>
        {data.meetingDate === "" || data.meetingDate === "Invalid date" ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 미팅일자가 입력되어
            있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        {data.setInfo && (
          <CrossEditor
            ref={refContent}
            name="InBodyAssignmentWork"
            params={params}
            value={data.content}
            onLoaded={onLoaded}
          />
        )}
      </Grid>
      {data.id !== 0 && (
        <Grid item xs={12} style={{ margin: "10px 0px" }}>
          기존파일 :{" "}
          {data.attachmentFiles &&
            data.attachmentFiles.map((x, idx) => (
              <span
                key={x.filename + idx}
                style={{ fontSize: "14px", margin: "3px" }}
              >
                {x.filename}
                <ButtonComponent
                  style={{ marginLeft: "3px", fontSize: "14px" }}
                  cssClass="e-small e-danger"
                  onClick={() => deleteExistFile(idx)}
                >
                  삭제
                </ButtonComponent>
              </span>
            ))}
        </Grid>
      )}
      <Grid item xs={12}>
        <input id="file" type="file" onChange={onSaveFile} multiple></input>
        <br />
        <div>
          {nameList &&
            nameList.map((e, idx) => (
              <div key={idx} style={{ fontSize: "14px" }}>
                {e}
                <IconButton
                  size="small"
                  style={{ padding: "0px" }}
                  onClick={() => deleteFile(idx)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
        </div>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <CustomButton iconCss="e-icons e-check" onClick={saveComplete}>
          저장
        </CustomButton>
      </Grid>
    </Grid>
  );
}
