import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../azure/authConfig";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class _HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(url?: string) {
    const baseURL = url ? url : `${window.location.origin}`;

    this.instance = axios.create({
      baseURL,
    });
    this.instance.defaults.withCredentials = true;
  }
}

export default class HttpClient extends _HttpClient {
  public constructor(url?: string) {
    super(url);
    this.instance.interceptors.request.use(this.requestInterceptor, (error) =>
      Promise.reject(error)
    );
  }

  private requestInterceptor = async (config: AxiosRequestConfig) => {
    const msalObj = new PublicClientApplication(msalConfig);
    const account = msalObj.getAllAccounts().at(0);

    const expTime =
      account?.idTokenClaims?.exp === undefined
        ? 0
        : account?.idTokenClaims?.exp;

    if (expTime <= Date.now() / 1000) {
      try {
        const response = await msalObj.acquireTokenSilent({
          ...loginRequest,
          account: account,
        });
        this.setHeaders(config, response.idToken);
      } catch (e) {
        console.log("silent token acquisition fails.", e);
        if (e instanceof InteractionRequiredAuthError) {
          console.log("acquiring token using redirect");
          await msalObj
            .acquireTokenPopup(loginRequest)
            .then((resp) => {
              this.setHeaders(config, resp.idToken);
            })
            .catch((err) => {
              console.error(err);
              if (err instanceof BrowserAuthError) {
                if (err.message.includes("user_cancelled")) {
                  alert(
                    "로그인을 취소하셨습니다.\n정상적인 사이트 이용이 불가능합니다."
                  );
                  window.location.replace("https://weareinbody.com");
                } else if (err.message.includes("popup_window_error"))
                  alert(
                    "팝업 차단 기능이 설정되어 있습니다.\n\n팝업 차단을 '해제'하고 '새로고침' 버튼을 눌러주세요."
                  );
              } else {
                alert("로그인에 실패했습니다. 관리자에게 문의해주세요.");
              }
              return null;
            });
        } else {
          console.error(e);
        }
      }
    } else {
      let idTokenObj;
      for (var key in localStorage) {
        const tenantId = process.env.REACT_APP_MSAL_TENENT_ID + "";
        const appId = process.env.REACT_APP_MSAL_CLIENT_ID + "";
        if (
          key.includes("idtoken") &&
          key.includes(tenantId) &&
          key.includes(appId)
        ) {
          const idTokenCredential = localStorage.getItem(key);
          idTokenObj = JSON.parse(idTokenCredential + "");
          break;
        }
      }
      this.setHeaders(config, idTokenObj["secret"]);
    }
    return config;
  };

  setHeaders(config: AxiosRequestConfig, idToken: string) {
    if (config.headers !== undefined) {
      config.headers["Authorization"] = `Bearer ${idToken}`;
    }
  }
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.instance.post<T, R>(url, data, config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.instance.get<T, R>(url, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.instance.put<T, R>(url, data, config);
  }
  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.instance.patch<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.instance.delete<T, R>(url, config);
  }
}

export class HttpUrlClient {
  protected prefix: string;
  private client: HttpClient;

  public constructor(client: HttpClient, prefix?: string) {
    this.client = client;
    this.prefix = prefix || "";
  }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise(
      (resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
        this.client
          .post<T>(this.prefix + url, data, config)
          .then((res) => resolve(res.data))
          .catch((error) => reject(error));
      }
    );
  }

  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return new Promise(
      (resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
        this.client
          .get<T>(this.prefix + url, config)
          .then((res) => resolve(res.data))
          .catch((error) => reject(error));
      }
    );
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise(
      (resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
        this.client
          .put<T>(this.prefix + url, data, config)
          .then((res) => resolve(res.data))
          .catch((error) => reject(error));
      }
    );
  }

  delete<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise(
      (resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
        this.client
          .delete<T>(this.prefix + url, {
            ...config,
            data: data,
          })
          .then((res) => resolve(res.data))
          .catch((error) => reject(error));
      }
    );
  }
}
