// Primary Colors
// Base
export const BaseWhite = "#FFFFFF";
export const BaseBlack = "#000000";

// Gray
export const Gray25 = "#F6F7F9";
export const Gray50 = "#EEEFF3";
export const Gray100 = "#E2E4E9";
export const Gray200 = "#C8CBD3";
export const Gray300 = "#AEB3BE";
export const Gray400 = "#949AA8";
export const Gray500 = "#7B818F";
export const Gray600 = "#636875";
export const Gray700 = "#4B4F5A";
export const Gray800 = "#34363E";
export const Gray900 = "#1C1D21";

// Brand
export const Brand25 = "#FBF3F5";
export const Brand50 = "#F7E3E7";
export const Brand100 = "#EDC0C7";
export const Brand200 = "#E29CA7";
export const Brand300 = "#D77584";
export const Brand400 = "#CB4D61";
export const Brand500 = "#B23448";
export const Brand600 = "#971B2F";
export const Brand700 = "#751524";
export const Brand800 = "#570F1B";
export const Brand900 = "#410C14";

// Secondary Colors
// Orange
export const Orange25 = "#FEF3EC";
export const Orange50 = "#FDEBDD";
export const Orange100 = "#FBD0B1";
export const Orange200 = "#F9B686";
export const Orange300 = "#F69855";
export const Orange400 = "#F47B25";
export const Orange500 = "#DA620B";
export const Orange600 = "#AA4C09";
export const Orange700 = "#833B07";
export const Orange800 = "#572705";
export const Orange900 = "#3A1A03";

// Yellow
export const Yellow25 = "#FEF9EC";
export const Yellow50 = "#FDF5DD";
export const Yellow100 = "#FBE9B1";
export const Yellow200 = "#F9DC86";
export const Yellow300 = "#F6CE55";
export const Yellow400 = "#F4C025";
export const Yellow500 = "#DAA60B";
export const Yellow600 = "#AA8109";
export const Yellow700 = "#836407";
export const Yellow800 = "#574305";
export const Yellow900 = "#3A2C03";

// Green
export const Green25 = "#F0FAF7";
export const Green50 = "#E4F6F2";
export const Green100 = "#C2EBE0";
export const Green200 = "#9FDFCF";
export const Green300 = "#79D2BC";
export const Green400 = "#53C6A9";
export const Green500 = "#39AC8F";
export const Green600 = "#2D8670";
export const Green700 = "#226756";
export const Green800 = "#174539";
export const Green900 = "#0F2E26";

// Blue
export const Blue25 = "#F0F6FA";
export const Blue50 = "#E4EEF6";
export const Blue100 = "#C2DAEB";
export const Blue200 = "#9FC5DF";
export const Blue300 = "#79ADD2";
export const Blue400 = "#5396C6";
export const Blue500 = "#397CAC";
export const Blue600 = "#2D6186";
export const Blue700 = "#224B67";
export const Blue800 = "#173245";
export const Blue900 = "#0F212E";

// Purple
export const Purple25 = "#F3F1F9";
export const Purple50 = "#EAE6F5";
export const Purple100 = "#CEC6E7";
export const Purple200 = "#B2A6D9";
export const Purple300 = "#9482C9";
export const Purple400 = "#755EBA";
export const Purple500 = "#5C45A1";
export const Purple600 = "#47367D";
export const Purple700 = "#372960";
export const Purple800 = "#251C40";
export const Purple900 = "#18122B";

// Semantic Colors
export const ErrorDefault = "#F04438";
export const ErrorBackground = "#FDE4E3";

export const WarningDefault = "#F79009";
export const WarningBackground = "#FEF2E1";

export const SuccessDefault = "#17B26A";
export const SuccessBackground = "#E4FBF1";

export const WaitingDefault = "#1782CF";
export const WaitingBackground = "#E3F2FC";
