import { Grid } from "@mui/material";
import { useState } from "react";
import AIReportTable from "./AIReportTable";
import AIReportView from "./AIReportView";
import AIReportEdit from "./AIReportEdit";
import { ActionItemDetailInfo } from "../../../system/types/ActionItem";

interface AIReportProps {
  data: ActionItemDetailInfo | null;
}
function AIReport({ data }: AIReportProps) {
  // table, detail, edit
  const [type, setType] = useState("table");
  const [noteId, setNoteId] = useState<number | null>(null);

  return (
    <>
      {data && (
        <Grid item xs={12}>
          {type === "table" && (
            <AIReportTable
              id={data.id}
              articleType="보고서"
              setNoteId={setNoteId}
              setType={setType}
            />
          )}
          {type === "detail" && (
            <AIReportView aiData={data} noteId={noteId} setType={setType} />
          )}
          {type === "edit" && (
            <AIReportEdit id={data.id} meetingId={noteId} setType={setType} />
          )}
        </Grid>
      )}
    </>
  );
}

export default AIReport;
