import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ChipIsResignation,
  CustomButton,
  StatisticsTitle,
  StyledBodyNoPadding,
  StyledHead1,
  StyledHeadTitle,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import StatisticsApi from "../../../system/api/StatisticsApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AggEmployeeDetail } from "../../../system/types/Statistics";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { AxiosResponse } from "axios";
import { SearchYears } from "../../../system/Constants";

interface MeritPctDetailProps {
  year: string;
  department: string | null;
  team: string | null;
}

function MeritPctDetail({ year, department, team }: MeritPctDetailProps) {
  const [data, setData] = useState<AggEmployeeDetail[]>([]);
  const Loading = useLoadingDispatch();
  const [searchYear, setSearchYear] = useState("");
  const [month, setMonth] = useState(0);
  useEffect(() => {
    Loading({ type: "LOADING" });
    if (searchYear) {
      department || team
        ? StatisticsApi.GetEmployeeStatistics({
            year: searchYear,
            month: month,
            department: department,
            team: team,
            userId: 0,
            isMeritPayCandidate: true,
          })
            .then((res) => {
              setData(res.data);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              alert(msg);
            })
            .finally(() => {
              Loading({ type: "COMPLETE" });
            })
        : StatisticsApi.GetEmployeeStatistics({
            year: searchYear,
            month: month,
            department: "",
            team: "",
            userId: 0,
            isMeritPayCandidate: true,
          })
            .then((res) => {
              setData(res.data);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              alert(msg);
            })
            .finally(() => {
              Loading({ type: "COMPLETE" });
            });
    } else {
      setSearchYear(year);
    }
  }, [department, team, year, searchYear, month, Loading]);

  const commonStyle = { whiteSpace: "nowrap" as const };

  const subTitle = {
    whiteSpace: "nowrap" as const,
    overflowX: "auto" as const,
    maxWidth: "300px",
  };
  function onChangYear(args: any) {
    setSearchYear(args.value);
  }

  function onChangeDate(args: any) {
    if (args.value === "전체") {
      setMonth(0);
    } else {
      setMonth(Number(args.value));
    }
  }

  function downloadFile() {
    department || team
      ? StatisticsApi.GetEmployeeStatistics({
          year: searchYear,
          month: month,
          department: department,
          team: team,
          isMeritPayCandidate: true,
          isFile: true,
        })
          .then((res) => {
            const blob = new Blob([res.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            link.style.display = "none";
            const injectFilename = (res: AxiosResponse<any, any>) => {
              var filename = "";
              var disposition = res.headers["content-disposition"];
              if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, "");
                }
              }
              return decodeURI(filename);
            };
            link.download = injectFilename(res);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          })
      : StatisticsApi.GetEmployeeStatistics({
          year: searchYear,
          month: month,
          isMeritPayCandidate: true,
          isFile: true,
        })
          .then((res) => {
            const blob = new Blob([res.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            link.style.display = "none";
            const injectFilename = (res: AxiosResponse<any, any>) => {
              var filename = "";
              var disposition = res.headers["content-disposition"];
              if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, "");
                }
              }
              return decodeURI(filename);
            };
            link.download = injectFilename(res);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <StatisticsTitle>
          {team ? (
            <>{team} 과제 성과급 인원 현황</>
          ) : (
            <>
              {department ? (
                <>{department} 과제 성과급 인원 현황</>
              ) : (
                "전사 과제 성과급 인원 현황"
              )}
            </>
          )}
        </StatisticsTitle>
      </Grid>
      <Grid item xs={2.5}></Grid>
      <Grid item xs={1.5} textAlign="right">
        {searchYear ? (
          <DropDownListComponent
            cssClass="e-outline"
            placeholder="년도"
            dataSource={SearchYears}
            onChange={onChangYear}
            value={searchYear}
            width={"120px"}
            style={{ minHeight: "29px" }}
          ></DropDownListComponent>
        ) : null}
      </Grid>
      <Grid item xs={1} textAlign="right">
        <DropDownListComponent
          cssClass="e-outline"
          placeholder="월"
          dataSource={[
            "전체",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ]}
          onChange={onChangeDate}
          value={month}
          width={"120px"}
          style={{ minHeight: "29px" }}
        ></DropDownListComponent>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: "right" }}>
        <CustomButton onClick={downloadFile} iconCss="e-icons e-download">
          엑셀 다운로드
        </CustomButton>
      </Grid>

      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHead1 align="center">파트</StyledHead1>
                <StyledHead1 align="center">팀</StyledHead1>
                <StyledHead1 align="center">이름</StyledHead1>
                <StyledHead1 align="center">직급</StyledHead1>
                <StyledHeadTitle align="center">과제명</StyledHeadTitle>
                <StyledHead1 align="center">PM/참여자</StyledHead1>
                <StyledHead1 align="center">총 성과급(원)</StyledHead1>
                <StyledHead1 style={{ width: "100px" }} align="center">
                  비율(%)
                </StyledHead1>
                <StyledHead1 align="center">실 성과급(원)</StyledHead1>
                <StyledHead1 align="center">지급시기</StyledHead1>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {item.department}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {item.team}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {item.name}
                        {item.isResignation && (
                          <ChipIsResignation>퇴사</ChipIsResignation>
                        )}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {item.jobPosition}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={subTitle}>
                        {item.workTitle}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {item.projectManager ? "PM" : "참여자"}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="right">
                        {item.meritPay
                          .toLocaleString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {item.contributionRate}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="right">
                        {item.actualMeritPay
                          .toLocaleString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {item.payPeriod}
                      </StyledBodyNoPadding>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default MeritPctDetail;
