import { Grid } from "@mui/material";
import { DropDownList } from "@syncfusion/ej2-react-dropdowns";
import {
  Column,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  CommandModel,
  Edit,
  EditSettingsModel,
  Filter,
  GridComponent,
  IFilterUI,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { useCallback, useEffect, useState } from "react";
import { ErrorHandler } from "../../../system/ApiService";
import AdminApi from "../../../system/api/AdminApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AdminUser, UserRole } from "../../../system/types/type";
import TitleDiv from "../../Common/TitleDiv";
import EmployeeAdminModal from "./EmployeeAdminModal";

function RoleAdminTable() {
  const Loading = useLoadingDispatch();
  const [data, setData] = useState<AdminUser[]>([]);
  const pageSettings: PageSettingsModel = { pageSize: 15 };
  const editOptions: EditSettingsModel = { allowDeleting: true };
  const commands: CommandModel[] = [
    {
      type: "Delete",
      buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
    },
  ];

  const onLoad = useCallback(() => {
    Loading({ type: "LOADING" });
    AdminApi.GetAdminList()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  }, [Loading]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const roleFilter: IFilterUI = {
    create: (args: { element: Element; column: Column }) => {
      const dd = document.createElement("input");
      dd.id = "displayName";
      return dd;
    },
    write: (args: { element: Element; column: Column }) => {
      let DropDownListObj: DropDownList = new DropDownList({
        dataSource: ["전체", "Admin", "HR"],
        fields: { text: "진행상태", value: "displayName" },
        popupHeight: "200px",
        change: function (e) {
          let gridObj = (document.getElementsByClassName("e-grid")[0] as any)
            .ej2_instances[0];
          if (e.value === "전체") {
            gridObj.removeFilteredColsByField("displayName");
          } else {
            gridObj.filterByColumn("displayName", "equal", e.value);
          }
        },
      });
      DropDownListObj.appendTo("#displayName");
    },
  };

  const actionBegin = (e: any) => {
    if (e.requestType === "delete") {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        Loading({ type: "LOADING" });
        AdminApi.DeleteAdmin(e.data[0])
          .then(() => {})
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => Loading({ type: "COMPLETE" }));
      } else {
        e.cancel = true;
      }
    }
  };

  const onReloading = (res: UserRole) => {
    if (res.role && res.user) {
      const newData = {
        appRoleId: "",
        principalId: "",
        roleAssignmentId: "",
        value: "",
        description: res.role?.description,
        displayName: res.role?.displayName,
        enabled: res.role?.isEnabled,
        principalUser: res.user,
      };
      setData((prevData) => prevData.concat(newData));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title="관리자 권한 설정"></TitleDiv>
      </Grid>
      <Grid item xs={10}></Grid>
      <Grid item xs={2} sx={{ textAlign: "right" }}>
        <EmployeeAdminModal onReloading={onReloading}></EmployeeAdminModal>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 1 }}>
        <GridComponent
          dataSource={data}
          allowPaging={true}
          pageSettings={pageSettings}
          allowFiltering={true}
          editSettings={editOptions}
          actionBegin={actionBegin}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="displayName"
              headerText="권한"
              textAlign="Center"
              width="150"
              filterBarTemplate={roleFilter}
            />
            <ColumnDirective
              field="principalUser.name"
              headerText="이름"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="principalUser.group"
              headerText="그룹"
              headerTextAlign="Center"
              textAlign="Center"
              width="150"
            />
            <ColumnDirective
              field="principalUser.department"
              headerText="파트"
              headerTextAlign="Center"
              textAlign="Center"
              width="150"
            />
            <ColumnDirective
              field="principalUser.team"
              headerText="팀"
              textAlign="Center"
              width="150"
            />
            <ColumnDirective
              field="principalUser.jobTitle"
              headerText="직책"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="principalUser.jobPosition"
              headerText="직위"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="principalUser.id"
              textAlign="Center"
              headerText=""
              width="50"
              commands={commands}
              allowFiltering={false}
            ></ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Edit, Page, Sort, Filter, CommandColumn]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default RoleAdminTable;
