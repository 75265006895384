import client from "../ApiService";
import HttpClient from "../http-client";
import {
  ActionItemMeetingNoteRequestInfo,
  ActionItemInfo,
  TriggeringReportInfo,
  ActionItemReportRequestInfo,
} from "../types/ActionItem";
import { AttachFile } from "../types/type";

const baseUri: string = "/action-items";
const triggeringReportsUri: string = "/action-items/triggering-reports";
const categoryUri: string = "/action-items/categories";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _ActionItemsApi extends _BasicApi {
  // 액션 아이템
  getActionItemsList(startDate: string, endDate: string, keyword?: string) {
    return this.client.get(`${baseUri}`, {
      params: { startDate: startDate, endDate: endDate, keyword: keyword },
    });
  }
  getActionItemsDetail(id: number) {
    return this.client.get(`${baseUri}/${id}`);
  }
  createActionItem(actionItemData: ActionItemInfo) {
    return this.client.post(`${baseUri}`, actionItemData);
  }
  updateActionItem(id: number, actionItemData: ActionItemInfo) {
    return this.client.put(`${baseUri}/${id}`, actionItemData);
  }
  deleteActionItem(id: number) {
    return this.client.delete(`${baseUri}/${id}`);
  }
  createActionItemAttachment(id: number, data: AttachFile) {
    return this.client.post(`${baseUri}/${id}/attachments`, data);
  }
  deleteActionItemAttachment(id: number, attachmentField: number) {
    return this.client.delete(
      `${baseUri}/${id}/attachments/${attachmentField}`
    );
  }

  updateActionItemStatus(id: number, status: string) {
    return this.client.patch(`${baseUri}/${id}/status?status=${status}`);
  }
  getActionItemsStatus(startDate: string, endDate: string, keyword?: string) {
    return this.client.get(`${baseUri}/status`, {
      params: { startDate: startDate, endDate: endDate, keyword: keyword },
    });
  }
  downloadActionItem(startDate: string, endDate: String) {
    return this.client.get(`${baseUri}/excel`, {
      params: { startDate: startDate, endDate: endDate },
      responseType: "blob",
    });
  }
  // 시작점
  getTriggeringReportsList() {
    return this.client.get(`${triggeringReportsUri}`);
  }
  getTriggeringReportsDetail(id: number) {
    return this.client.get(`${triggeringReportsUri}/${id}`);
  }
  createTriggeringReports(triggeringReportData: TriggeringReportInfo) {
    return this.client.post(`${triggeringReportsUri}`, triggeringReportData);
  }
  updateTriggeringReports(
    id: number,
    triggeringReportData: TriggeringReportInfo
  ) {
    return this.client.put(
      `${triggeringReportsUri}/${id}`,
      triggeringReportData
    );
  }
  deleteTriggeringReports(id: number) {
    return this.client.delete(`${triggeringReportsUri}/${id}`);
  }

  // 카테고리
  getAICategoryList() {
    return this.client.get(`${categoryUri}`);
  }
  createAICategory(name: string) {
    return this.client.post(`${categoryUri}`, { params: { name: name } });
  }
  updateAICategory(id: number, name: string) {
    return this.client.put(`${categoryUri}/${id}`, { params: { name: name } });
  }
  deleteAICategory(id: number) {
    return this.client.delete(`${categoryUri}/${id}`);
  }

  // 노트
  getActionItemNoteList(actionItemId: number, articleType: string) {
    return this.client.get(`${baseUri}/${actionItemId}/${articleType}`);
  }
  getActionItemNoteDetail(
    actionItemId: number,
    articleType: string,
    articleId: number
  ) {
    return this.client.get(
      `${baseUri}/${actionItemId}/${articleType}/${articleId}`
    );
  }
  createActionItemNote(
    actionItemId: number,
    articleType: string,
    data: ActionItemMeetingNoteRequestInfo | ActionItemReportRequestInfo
  ) {
    return this.client.post(`${baseUri}/${actionItemId}/${articleType}`, data);
  }
  updateActionItemNote(
    actionItemId: number,
    articleType: string,
    articleId: number,
    data: ActionItemMeetingNoteRequestInfo | ActionItemReportRequestInfo
  ) {
    return this.client.put(
      `${baseUri}/${actionItemId}/${articleType}/${articleId}`,
      data
    );
  }
  deleteActionItemNote(
    actionItemId: number,
    articleType: string,
    articleId: number
  ) {
    return this.client.delete(
      `${baseUri}/${actionItemId}/${articleType}/${articleId}`
    );
  }

  createActionItemNoteAttachment(
    actionItemId: number,
    articleType: string,
    articleId: number,
    formData: AttachFile
  ) {
    return this.client.post(
      `${baseUri}/${actionItemId}/${articleType}/${articleId}/attachments`,
      formData
    );
  }
  deleteActionItemNoteAttachment(
    actionItemId: number,
    articleType: string,
    articleId: number,
    attachmentFileId: number
  ) {
    return this.client.delete(
      `${baseUri}/${actionItemId}/${articleType}/${articleId}/attachments/${attachmentFileId}`
    );
  }
}

export const ActionItemsApi = new _ActionItemsApi(client);
