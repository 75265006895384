import { Grid } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CustomButton, CustomButtonRed } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import AsmtWorkApi from "../../../system/api/AsmtApi";
import UserApi from "../../../system/api/UserApi";
import FileApi from "../../../system/api/fileApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { useUserState } from "../../../system/context/UserContext";
import { AsmtDetail } from "../../../system/types/AsmtWork";
import { InitAsmtDetail } from "../../../system/types/initObject";
import { AsmtWorkRoutes, WorkUser } from "../../../system/types/type";
import CrossEditor from "../../Common/Editor/CrossEditor";
import TitleDiv from "../../Common/TitleDiv";
import BasicStep from "./BasicInfo";
import ContentStep from "./ContentInfo";

interface Params {
  id: string;
}

function validation(data: AsmtDetail, mentorEmpList: WorkUser[]) {
  var checkMentor = false;
  for (let i = 0; data.participants.length > i; i++) {
    if (
      mentorEmpList.filter(
        (item) =>
          item.name === data.participants[i].name &&
          (data.participants[i].contributionRate === undefined ||
            data.participants[i].contributionRate === 0)
      ).length > 0
    ) {
      checkMentor = true;
    }
  }

  if (
    data.participants.length < 1 ||
    data.participants.filter((x) => x.projectManager).length === 0
  ) {
    alert("1명 이상의 PM이 지정되어야 합니다.");
    return false;
  } else if (
    data.participants
      .filter((x) => x.contributionRate)
      .reduce((x, y) => x + (y.contributionRate || 0), 0) !== 100
  ) {
    alert("모든 참여자 기여도가 0이상, 기여도 합이 100이 되어야 합니다.");
    return false;
  } else if (data.classification === "" || data.classification === null) {
    alert("분류를 선택해 주세요.");
    return false;
  } else if (data.startDate === "" || data.expectedEndDate === "") {
    alert("시작일과 예상 종료일을 정확히 입력해 주세요.");
    return false;
  } else if (data.title === "") {
    alert("과제명이 입력되어 있지 않습니다.");
    return false;
  } else if (checkMentor) {
    alert(
      "과제 멘토를 과제 참여자로 등록하려면 \n 과제 멘토 기여도를 0 보다 큰 숫자로 입력해주셔야 합니다."
    );
    return false;
  }
  return true;
}

export default function AsmtEdit() {
  const { id } = useParams<Params>();
  const asmtId = parseInt(id);
  const history = useHistory();
  const user = useUserState();
  const refContent = createRef<CrossEditor>();
  const [fileList, setFileList] = useState<File[]>([]);
  const [data, setData] = useState<AsmtDetail>(InitAsmtDetail);
  const loadingDispatch = useLoadingDispatch();
  const [mentorEmpList, setMentorEmpList] = useState<WorkUser[]>([]);
  const Loading = useLoadingDispatch();
  const [started, setStarted] = useState<boolean>(false);
  var submitcheck = false;

  useEffect(() => {
    if (asmtId) {
      Loading({ type: "LOADING" });
      AsmtWorkApi.GetAsmtworkDetail(asmtId)
        .then((res) => {
          setData({
            ...res.data,
            participants: res.data.participants.filter(
              (x) =>
                !x.mentor ||
                (x.mentor &&
                  x.contributionRate !== undefined &&
                  x.contributionRate > 0)
            ),
            setInfo: true,
          });
          setMentorEmpList(res.data.participants.filter((mt) => mt.mentor));
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    } else {
      Loading({ type: "LOADING" });
      UserApi.GetUsers(user.name)
        .then((res) => {
          setData({ ...data, participants: res.data, setInfo: true });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asmtId, user.name]);

  const onChangeData = (_data: AsmtDetail) => {
    setData(_data);
  };

  const onChangeFile = (_file: File[]) => {
    setFileList(_file);
  };

  const onChangeMentor = (_mentor: WorkUser[]) => {
    setMentorEmpList(_mentor);
  };

  const saveComplete = (state: string) => {
    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }

    // 최종 저장
    if (submitcheck === false && refContent.current) {
      const editor = refContent.current.editorRef;
      submitcheck = true;
      if (validation(data, mentorEmpList)) {
        let promise: Promise<any>;
        loadingDispatch({ type: "LOADING" });
        if (fileList.length > 0) {
          const formData = new FormData();
          Array.from(fileList).forEach((el) => {
            formData.append("uploadFiles", el);
          });

          FileApi.upload(formData)
            .then((file) => {
              if (data.id === 0) {
                promise = AsmtWorkApi.CreateAsmtworkDetail({
                  ...data,
                  status: state,
                  attachmentFiles: data.attachmentFiles.concat(file),
                  content: editor.GetBodyValue() || "",
                  participants: data.participants.concat(mentorEmpList),
                });
              } else {
                promise = AsmtWorkApi.UpdateAsmtworkDetail({
                  ...data,
                  status: state,
                  attachmentFiles: data.attachmentFiles.concat(file),
                  content: editor.GetBodyValue() || "",
                  participants: data.participants.concat(mentorEmpList),
                });
              }
              promise
                .then((res) => {
                  setData(res.data);
                  if (state === "임시저장") {
                    alert(
                      "임시저장이 완료되었습니다. '나의 과제업무'에서 수정 및 삭제가 가능합니다."
                    );
                  } else if (state === "등록대기") {
                    alert(
                      "등록한 과제업무는 '나의 과제 업무'에서 확인해 주세요."
                    );
                  } else {
                    alert("정상적으로 등록되었습니다.");
                  }
                  history.push(`${AsmtWorkRoutes.root}/detail/${res.data.id}`);
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  alert(msg);
                });
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              alert(msg);
            })
            .finally(() => {
              loadingDispatch({ type: "COMPLETE" });
            });
        } else {
          if (data.id === 0) {
            promise = AsmtWorkApi.CreateAsmtworkDetail({
              ...data,
              status: state,
              content: editor.GetBodyValue() || "",
              participants: data.participants.concat(mentorEmpList),
            });
          } else {
            promise = AsmtWorkApi.UpdateAsmtworkDetail({
              ...data,
              status: state,
              content: editor.GetBodyValue() || "",
              participants: data.participants.concat(mentorEmpList),
            });
          }
          loadingDispatch({ type: "LOADING" });
          promise
            .then((res) => {
              setData(res.data);
              if (state === "임시저장") {
                alert(
                  "임시저장이 완료되었습니다. '나의 과제업무'에서 수정 및 삭제가 가능합니다."
                );
              } else if (state === "등록대기") {
                alert("등록한 과제업무는 '나의 과제 업무'에서 확인해 주세요.");
              } else {
                alert("정상적으로 등록되었습니다.");
              }
              history.push(`${AsmtWorkRoutes.root}/detail/${res.data.id}`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              alert(msg);
            })
            .finally(() => {
              loadingDispatch({ type: "COMPLETE" });
            });
        }
      }
    }
  };

  const onLoaded = () => {
    setStarted(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="과제업무 등록"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <BasicStep
          data={data}
          onChangeData={onChangeData}
          mentorEmpList={mentorEmpList}
          onChangeMentor={onChangeMentor}
        ></BasicStep>
      </Grid>
      <Grid item xs={12}>
        <ContentStep
          data={data}
          refContent={refContent}
          onChangeData={onChangeData}
          fileList={fileList}
          onChangeFile={onChangeFile}
          onLoaded={onLoaded}
        ></ContentStep>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        {data.status === "임시저장" ? (
          <>
            <CustomButton
              iconCss="e-icons e-save"
              style={{ marginRight: "10px" }}
              onClick={() => saveComplete("임시저장")}
            >
              {"임시저장"}
            </CustomButton>
            <CustomButtonRed
              iconCss="e-icons e-check"
              onClick={() => saveComplete("등록대기")}
            >
              {"등록요청"}
            </CustomButtonRed>
          </>
        ) : (
          <CustomButtonRed
            iconCss="e-icons e-check"
            onClick={() => saveComplete(data.status)}
          >
            {"저장"}
          </CustomButtonRed>
        )}
      </Grid>
    </Grid>
  );
}
