import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton } from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CustomButton, CustomButtonRed } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { InternApi } from "../../../system/api/InternApi";
import UserApi from "../../../system/api/UserApi";
import FileApi from "../../../system/api/fileApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { useUserState } from "../../../system/context/UserContext";
import { InternWorkDetail } from "../../../system/types/InternWork";
import { InitInternWorkDetail } from "../../../system/types/initObject";
import { AsmtWorkRoutes, WorkUser } from "../../../system/types/type";
import CrossEditor from "../../Common/Editor/CrossEditor";
import SelecMentorEmployees from "../../Common/SelectMentorEmployee";
import TitleDiv from "../../Common/TitleDiv";

interface Params {
  id: string;
}

function InternEdit() {
  const { id } = useParams<Params>();
  const internId = parseInt(id);
  const history = useHistory();
  const user = useUserState();
  const refContent = createRef<CrossEditor>();
  const [fileList, setFileList] = React.useState<File[]>([]);
  const [data, setData] = useState<InternWorkDetail>(InitInternWorkDetail);
  const [nameList, setNameList] = React.useState<string[]>([]);
  const [started, setStarted] = useState<boolean>(false);
  const loadingDispatch = useLoadingDispatch();
  const params = {
    Width: "100%",
    Height: 800,
  };

  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  useEffect(() => {
    if (internId) {
      loadingDispatch({ type: "LOADING" });
      InternApi.getInternWorkDetail(internId)
        .then((res) => {
          setData({ ...res.data, setInfo: true });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => loadingDispatch({ type: "COMPLETE" }));
    } else {
      loadingDispatch({ type: "LOADING" });
      UserApi.GetUsers(user.name)
        .then((res) => {
          setData({ ...data, participants: res.data, setInfo: true });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => loadingDispatch({ type: "COMPLETE" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internId, user.name]);

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeEmp = (userList: WorkUser[]) => {
    if (userList) {
      setData({ ...data, participants: userList });
    }
  };

  const onClickChip = (id: number) => {
    const newList = data.participants;
    var emp = data.participants.findIndex((x) => x.id === id);
    var state = newList[emp].mentor;
    if (emp !== -1) {
      newList[emp] = { ...newList[emp], mentor: !state };
    }
    setData({ ...data, participants: newList });
  };

  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      // 미리보기 기능
      const targetFiles = Array.from(event.target.files);
      const totalSize = targetFiles.reduce((acc, file) => acc + file.size, 0);
      const maxSize = 1024 * 1024 * 1024;
      if (totalSize > maxSize) {
        alert("업로드 파일은 1GB를 넘을 수 없습니다.");
        return;
      }
      setFileList(targetFiles);
      const selectedFile: string[] = [];
      targetFiles.map((file) => {
        return selectedFile.push(file.name);
      });
      setNameList(selectedFile);
    }
  };

  const deleteFile = (e: any) => {
    const fileArr = nameList.filter((x, idx) => idx !== e);
    const uploadArr = fileList.filter((x, idx) => idx !== e);
    setNameList([...fileArr]);
    setFileList([...uploadArr]);
  };
  var submitcheck = false;
  
  const onSaveWork = (state: string) => {
    // validation

    if (
      data.participants.length < 1 ||
      data.participants.filter((x) => x.mentor).length === 0
    ) {
      alert("1명 이상의 멘토가 지정되어야 합니다.");
      return;
    } else if (data.participants.filter((x) => !x.mentor).length === 0) {
      alert("참여자에는 인턴이 반드시 포함되어야 합니다.");
      return;
    } else if (data.title === "") {
      alert("모든 입력값을 채워주세요.");
      return;
    }

    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }

    // 최종 저장
    if (submitcheck === false && refContent.current) {
      submitcheck = true;
      const editor = refContent.current.editorRef;
      let promise: Promise<any>;
      loadingDispatch({ type: "LOADING" });
      if (fileList.length > 0) {
        const formData = new FormData();
        Array.from(fileList).forEach((el) => {
          formData.append("uploadFiles", el);
        });
        FileApi.upload(formData)
          .then((file) => {
            if (data.id === 0) {
              promise = InternApi.createInternWork({
                ...data,
                status: state,
                attachmentFiles: data.attachmentFiles.concat(file),
                content: editor.GetBodyValue() || "",
              });
            } else {
              promise = InternApi.updateInternWork({
                ...data,
                status: state,
                attachmentFiles: data.attachmentFiles.concat(file),
                content: editor.GetBodyValue() || "",
                startDate: moment(new Date()).format("YYYY-MM-DD"),
              });
            }
            promise
              .then((res) => {
                setData(res.data);
                if (state === "임시저장") {
                  alert(
                    "임시저장이 완료되었습니다. '나의 과제업무'에서 수정 및 삭제가 가능합니다."
                  );
                } else if (state === "등록대기") {
                  alert(
                    "등록한 과제업무는 '나의 과제 업무'에서 확인해 주세요."
                  );
                } else {
                  alert("정상적으로 등록되었습니다.");
                }
                history.push(`${AsmtWorkRoutes.intern}/detail/${res.data.id}`);
              })
              .catch((err) => {
                let msg = ErrorHandler(err);
                alert(msg);
              });
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            loadingDispatch({ type: "COMPLETE" });
          });
      } else {
        if (data.id === 0) {
          promise = InternApi.createInternWork({
            ...data,
            status: state,
            content: editor.GetBodyValue() || "",
          });
        } else {
          promise = InternApi.updateInternWork({
            ...data,
            status: state,
            content: editor.GetBodyValue() || "",
            startDate: moment(new Date()).format("YYYY-MM-DD"),
          });
        }
        loadingDispatch({ type: "LOADING" });
        promise
          .then((res) => {
            setData(res.data);
            if (state === "임시저장") {
              alert(
                "임시저장이 완료되었습니다. '나의 과제업무'에서 수정 및 삭제가 가능합니다."
              );
            } else if (state === "등록대기") {
              alert("등록한 과제업무는 '나의 과제 업무'에서 확인해 주세요.");
            } else {
              alert("정상적으로 등록되었습니다.");
            }
            history.push(`${AsmtWorkRoutes.intern}/detail/${res.data.id}`);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            loadingDispatch({ type: "COMPLETE" });
          });
      }
    }
  };

  const deleteExistFile = (idx: number) => {
    if (
      window.confirm(
        "파일을 정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다."
      )
    ) {
      setData({
        ...data,
        attachmentFiles: data.attachmentFiles.filter((x, i) => i !== idx),
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="인턴 과제 기획서 등록"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <div>과제명</div>
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          placeholder="과제명을 입력해 주세요."
          style={{ height: "48px" }}
          cssClass="e-outline"
          value={data.title}
          type="text"
          name="title"
          onChange={onChange}
        />
        {data.title.length === 0 ? (
          <div
            style={{ color: "#CC1F3B", fontSize: "0.8rem", marginTop: "10px" }}
          >
            {" "}
            <span className="e-icons e-circle-check"></span> 과제명이 입력되어
            있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={11}>
        <div>
          참여자{" "}
          <span
            style={{ color: "#1E66D9", fontSize: "0.8rem", marginTop: "10px" }}
          >
            * 참여자 이름을 클릭하여 역할(멘토[MT], 인턴 참여자)을 변경할 수
            있습니다.
          </span>
        </div>
      </Grid>

      <Grid item xs={1} style={{ textAlign: "end" }}>
        <div>
          총 {data.participants.length > 0 ? data.participants.length : 0} 명
        </div>
      </Grid>
      <Grid item xs={12}>
        <SelecMentorEmployees
          list={data.participants}
          onChange={onChangeEmp}
          onClickChip={onClickChip}
        ></SelecMentorEmployees>
        {data.participants.length < 1 ? (
          <div
            style={{ color: "#CC1F3B", fontSize: "0.8rem", marginTop: "10px" }}
          >
            <span className="e-icons e-circle-check"></span> 참여자가 지정되어
            있지 않습니다.
          </div>
        ) : (
          <>
            {data.participants.filter((x) => x.mentor).length === 0 ? (
              <div
                style={{
                  color: "#CC1F3B",
                  fontSize: "0.8rem",
                  marginTop: "10px",
                }}
              >
                <span className="e-icons e-circle-check"></span> 멘토 설정이
                되어 있지 않습니다. (1명 이상의 멘토가 지정되어야 합니다.)
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        {data.setInfo && (
          <CrossEditor
            ref={refContent}
            name="InBodyAssignmentWork"
            params={params}
            value={data.content}
            onLoaded={onLoaded}
          />
        )}
      </Grid>
      {data.id !== 0 && (
        <Grid item xs={12} style={{ margin: "10px 0px" }}>
          기존파일 :{" "}
          {data.attachmentFiles &&
            data.attachmentFiles.map((x, idx) => (
              <span
                key={x.filename + idx}
                style={{ fontSize: "14px", margin: "3px" }}
              >
                {x.filename}
                <ButtonComponent
                  style={{ marginLeft: "3px", fontSize: "14px" }}
                  cssClass="e-small e-danger"
                  onClick={() => deleteExistFile(idx)}
                >
                  삭제
                </ButtonComponent>
              </span>
            ))}
        </Grid>
      )}
      <Grid item xs={12}>
        <input id="file" type="file" onChange={onSaveFile} multiple></input>
        <br />
        <div>
          {nameList &&
            nameList.map((e, idx) => (
              <div key={idx} style={{ fontSize: "14px" }}>
                {e}
                <IconButton
                  size="small"
                  style={{ padding: "0px" }}
                  onClick={() => deleteFile(idx)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
        </div>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        {data.status === "임시저장" ? (
          <>
            <CustomButton
              iconCss="e-icons e-save"
              style={{ marginRight: "10px" }}
              onClick={() => onSaveWork("임시저장")}
            >
              {"임시저장"}
            </CustomButton>
            <CustomButtonRed
              iconCss="e-icons e-check"
              onClick={() => onSaveWork("등록대기")}
            >
              {"등록요청"}
            </CustomButtonRed>
          </>
        ) : (
          <CustomButtonRed
            iconCss="e-icons e-check"
            onClick={() => onSaveWork(data.status)}
          >
            {"저장"}
          </CustomButtonRed>
        )}
      </Grid>
    </Grid>
  );
}

export default InternEdit;
