import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { CustomButton, CustomButtonRed } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { CommentApi } from "../../../system/api/Comment";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { useUserState } from "../../../system/context/UserContext";
import { InitCommentInfo } from "../../../system/types/initObject";
import { CommentInfo, WorkUser } from "../../../system/types/type";
import CommentBox from "./CommentBox";

interface CompleteStepProps {
  data: any;
  setCommentNumber: any;
  cType: Type;
}

export type CommentType = "view" | "edit" | "add";
export type Type = "article" | "asmtWork" | "actionItem";

function CommentTable({ data, setCommentNumber, cType }: CompleteStepProps) {
  const user = useUserState();
  const Loading = useLoadingDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentType, setCommentType] = useState<CommentType>("view");
  const [selectedComment, setSelectedComment] = useState<CommentInfo>({
    ...InitCommentInfo,
    type: cType,
  });
  const [anchorE2, setAnchorE2] = useState(null);
  const [recommentType, setRecommentType] = useState<CommentType>("view");
  const [selectedRecomment, setSelectedRecomment] = useState<CommentInfo>({
    ...InitCommentInfo,
    type: cType,
    depth: 1,
  });
  const [pmList, setPmList] = useState([""]);
  const [internList, setInternList] = useState([""]);

  useEffect(() => {
    if (data?.participants) {
      const newPmList = data.participants
        .filter((item: WorkUser) => item.projectManager === true)
        .map((item: WorkUser) => item.name);

      const newInternList = data.participants
        .filter((item: WorkUser) => item.mentor === false)
        .map((item: WorkUser) => item.name);

      if (data.workType === "인턴") {
        setInternList(newInternList);
      } else {
        setPmList(newPmList);
      }
    }
  }, [data]);

  // 댓글 관리 - 댓글 수정, 삭제
  // 버튼 관리
  const handleClick = (event: any, object: CommentInfo) => {
    setCommentType("view");
    setSelectedComment(object);
    setAnchorEl(event.currentTarget);
    setAnchorE2(null);
    setRecommentType("view");
  };
  const handleClose = () => {
    setCommentType("view");
    setAnchorEl(null);
  };
  const handleDelete = (id: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      if (id !== 0) {
        Loading({ type: "LOADING" });
        CommentApi.DeleteComment(id)
          .then(() => {
            setSelectedRecomment({
              ...InitCommentInfo,
              type: cType,
            });
            setCommentNumber(new Date());
            setAnchorEl(null);
            setAnchorE2(null);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => Loading({ type: "COMPLETE" }));
      }
    }
  };
  const handleEdit = () => {
    setCommentType("edit");
    setAnchorEl(null);
    setRecommentType("view");
  };
  const cancelEdit = () => {
    setCommentType("view");
    setAnchorEl(null);
  };
  // 댓글 등록
  const onAddComment = (id: number) => {
    if (recommentType === "view") {
      setRecommentType("add");
      setSelectedRecomment({ ...selectedRecomment, parentId: id, depth: 0 });
    } else if (recommentType === "add") {
      setRecommentType("view");
      setSelectedRecomment({
        ...InitCommentInfo,
        type: cType,
      });
    } else if (recommentType === "edit") {
      setRecommentType("view");
      setSelectedRecomment({
        ...InitCommentInfo,
        type: cType,
      });
    }
  };

  // 대댓글 관리 - 댓글 수정, 삭제
  // 버튼 관리
  const handleRecommentClick = (event: any, object: CommentInfo) => {
    setRecommentType("view");
    setSelectedRecomment(object);
    setAnchorE2(event.currentTarget);
    setCommentType("view");
    setAnchorEl(null);
  };
  const handleRecommentClose = () => {
    setRecommentType("view");
    setAnchorE2(null);
  };

  const handleRecommentEdit = () => {
    setRecommentType("edit");
    setAnchorE2(null);
    setCommentType("view");
  };
  const cancelRecommentEdit = () => {
    setRecommentType("view");
    setAnchorE2(null);
  };
  //데이터 관리
  const onChangeRecomment = (e: any) => {
    setSelectedRecomment({
      ...selectedRecomment,
      [e.target.name]: e.target.value,
    });
  };

  const createRecomment = (id: number) => {
    Loading({ type: "LOADING" });
    CommentApi.CreateComment({
      ...selectedRecomment,
      parentId: id,
      depth: 1,
      targetId: data.id,
      sendMail: true,
    })
      .then(() => {
        setSelectedRecomment({
          ...InitCommentInfo,
          type: cType,
        });
        setCommentNumber(new Date());
        setCommentType("view");
        setRecommentType("view");
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };
  const updateRecomment = () => {
    Loading({ type: "LOADING" });
    CommentApi.UpdateComment(selectedRecomment)
      .then(() => {
        setCommentNumber(new Date());
        setRecommentType("view");
        setCommentType("view");
        setSelectedRecomment({
          ...InitCommentInfo,
          type: cType,
        });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };
  // 확인 요청
  const handleConfirm = (id: number) => {
    Loading({ type: "LOADING" });
    CommentApi.ConfirmComment(id)
      .then(() => {
        setCommentNumber(new Date());
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  return (
    <TableContainer style={{ marginTop: "10px" }}>
      <Table>
        <TableBody>
          <TableRow>
            {/* 댓글 등록 */}
            <TableCell style={{ border: "1px solid #e0e0e0" }}>
              <CommentBox
                data={{
                  ...InitCommentInfo,
                  type: cType,
                }}
                targetId={data.id}
                setCommentNumber={setCommentNumber}
                setCommentType={setCommentType}
              ></CommentBox>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                minHeight: "50px",
                borderBottom:
                  cType === "actionItem" ? "0px" : "1px solid #e0e0e0",
              }}
            >
              {data.comments.length === 0 ? (
                <Typography variant="body2">작성된 내용이 없습니다.</Typography>
              ) : (
                <>
                  {data.comments.map((x: CommentInfo, idx: number) => (
                    <Grid container key={"comment" + idx}>
                      {/* 댓글 - 수정/삭제 */}
                      <Grid
                        container
                        spacing={1}
                        style={{ paddingBottom: "10px", alignItems: "center" }}
                      >
                        <Grid item xs={10}>
                          <Typography variant="body2">
                            {x.user.team + "/" + x.user.name}
                            <Typography
                              variant="body2"
                              component="span"
                              style={{ marginLeft: "10px", color: "#81848B" }}
                            >
                              {x.createdAt}&nbsp;
                              {x.createdAt !== x.updatedAt ? "(수정됨)" : ""}
                            </Typography>
                          </Typography>
                        </Grid>

                        <Grid item xs={2} textAlign={"end"}>
                          {commentType === "edit" &&
                          x.id === selectedComment.id ? (
                            <Typography
                              variant="body2"
                              style={{ cursor: "pointer" }}
                              onClick={cancelEdit}
                            >
                              취소
                            </Typography>
                          ) : (
                            <>
                              {x.user.name === user.name && (
                                <>
                                  <IconButton
                                    onClick={(event) => handleClick(event, x)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    PaperProps={{
                                      sx: {
                                        boxShadow: "0",
                                        border: "1px solid #e0e0e0",
                                        backgroundColor: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <MenuItem onClick={handleEdit}>
                                      수정
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleDelete(selectedComment.id)
                                      }
                                    >
                                      삭제
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              whiteSpace: "break-spaces",
                              wordBreak: "break-all",
                            }}
                            variant="body2"
                          >
                            {x.content}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {commentType !== "edit" && (
                            <CustomButton
                              onClick={() => {
                                onAddComment(x.id);
                              }}
                            >
                              답글
                            </CustomButton>
                          )}
                          {user.roles.includes("Admin") ||
                          (pmList.includes(user.name) &&
                            data.workType === "일반") ||
                          (internList.includes(user.name) &&
                            data.workType === "인턴") ? (
                            <>
                              {data.participants &&
                                data.participants.findIndex(
                                  (x: any) => x.name === user.name
                                ) !== -1 &&
                                x.examine && (
                                  <>
                                    {x.confirm ? (
                                      <div style={{ color: "green" }}>
                                        확인완료
                                      </div>
                                    ) : (
                                      <CustomButtonRed
                                        onClick={() => handleConfirm(x.id)}
                                      >
                                        확인하기 ({x.dueDate})
                                      </CustomButtonRed>
                                    )}
                                  </>
                                )}
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                      {/* 댓글 수정 */}
                      {commentType === "edit" &&
                        x.id === selectedComment.id && (
                          <CommentBox
                            data={selectedComment}
                            targetId={data.id}
                            setCommentNumber={setCommentNumber}
                            setCommentType={setCommentType}
                          ></CommentBox>
                        )}
                      {/* 대댓글 */}
                      <Grid
                        container
                        style={{
                          marginLeft: "20px",
                          background: "#F4F4F4",
                          width: "100%",
                        }}
                      >
                        {/* 대댓글 추가 */}
                        {(commentType === "add" || recommentType === "add") &&
                        x.id === selectedRecomment.parentId ? (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "10px",
                            }}
                          >
                            <textarea
                              style={{ width: "100%", marginRight: "30px" }}
                              value={selectedRecomment.content}
                              onChange={onChangeRecomment}
                              placeholder="답글을 입력해주세요."
                              name="content"
                            ></textarea>
                            <CustomButton
                              onClick={() => {
                                createRecomment(x.id);
                              }}
                            >
                              등록
                            </CustomButton>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid
                          item
                          xs={12}
                          style={{ backgroundColor: "#F4F4F4" }}
                        >
                          {x.children.map((child: CommentInfo, idx) => (
                            <Grid
                              container
                              key={"recomment" + idx}
                              spacing={1}
                              style={{
                                padding: "10px 15px 10px 15px",
                                alignItems: "center",
                              }}
                            >
                              <Grid item xs={10}>
                                <Typography variant="body2">
                                  {child.user.team + "/" + child.user.name}
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    style={{
                                      marginLeft: "10px",
                                      color: "#81848B",
                                    }}
                                  >
                                    {child.createdAt} &nbsp;
                                    {child.createdAt !== child.updatedAt
                                      ? "(수정됨)"
                                      : ""}
                                  </Typography>
                                </Typography>
                              </Grid>
                              {child.user.name === user.name && (
                                <Grid item xs={2} textAlign={"end"}>
                                  {recommentType === "edit" &&
                                  child.id === selectedRecomment.id ? (
                                    <Typography
                                      variant="body2"
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                      onClick={cancelRecommentEdit}
                                    >
                                      취소
                                    </Typography>
                                  ) : (
                                    <>
                                      <IconButton
                                        onClick={(event) =>
                                          handleRecommentClick(event, child)
                                        }
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        anchorEl={anchorE2}
                                        keepMounted
                                        open={Boolean(anchorE2)}
                                        onClose={handleRecommentClose}
                                        PaperProps={{
                                          sx: {
                                            boxShadow: "0",
                                            border: "1px solid #e0e0e0",
                                            backgroundColor: "#FFFFFF",
                                          },
                                        }}
                                      >
                                        <MenuItem onClick={handleRecommentEdit}>
                                          수정
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            handleDelete(selectedRecomment.id)
                                          }
                                        >
                                          삭제
                                        </MenuItem>
                                      </Menu>
                                    </>
                                  )}
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <pre>
                                  <Typography variant="body2">
                                    {child.content}
                                  </Typography>
                                </pre>
                              </Grid>
                              <Grid item xs={12}>
                                {recommentType !== "edit" &&
                                child.depth === 0 ? (
                                  <CustomButton
                                    onClick={() => {
                                      onAddComment(x.id);
                                    }}
                                  >
                                    답글
                                  </CustomButton>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              {recommentType === "edit" &&
                              child.id === selectedRecomment.id ? (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <textarea
                                    style={{
                                      marginRight: "10px",
                                      width: "95%",
                                      resize: "none",
                                    }}
                                    name="content"
                                    value={selectedRecomment.content || ""}
                                    onChange={onChangeRecomment}
                                  ></textarea>
                                  <CustomButton onClick={updateRecomment}>
                                    저장
                                  </CustomButton>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>

                      <hr
                        style={{ border: "1px solid #e0e0e0", width: "100%" }}
                      />
                    </Grid>
                  ))}
                </>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CommentTable;
