import moment from "moment";
import { AsmtDetail, AsmtList, AsmtSearch, SearchDetail } from "./AsmtWork";
import { DevelopNoteDetail } from "./DevelopNote";
import { scheduleConfirm } from "./Hr";
import { InternWorkDetail, InternWorkList } from "./InternWork";
import { MeetingNoteDetail } from "./MeetingNote";
import { ReportDetail } from "./Report";
import { AggState } from "./Statistics";
import { dailyWorkWrite } from "./dailyWork";
import { AuthorityUser } from "./interface";
import {
  AttachFile,
  CommentInfo,
  Notice,
  Qna,
  QnaContent,
  WorkUser,
} from "./type";
import { defaultValue } from "../../components/Common/Editor/defaultValue";
import { defaultInternValue } from "../../components/Common/Editor/defaultInternValue";
import {
  ActionItemMeetingNoteRequestInfo,
  ActionItemInfo,
  ActionItemStatusInfo,
  BasicInfo,
  TriggeringReportInfo,
  ActionItemReportRequestInfo,
} from "./ActionItem";

export const InitUser: AuthorityUser = {
  department: "",
  departmentId: 0,
  email: "",
  employeeId: "",
  employeeNo: "",
  gwMail: "",
  gwName: "",
  jobPosition: "",
  jobTitle: "",
  name: "",
  notifications: [],
  positionCode: "",
  role: "",
  roles: [],
  team: "",
  teamId: 0,
  workplace: "",
};

export const InitSearch: SearchDetail = {
  type: "전체",
  word: "",
};

export const InitAsmtList: AsmtList = {
  works: [],
  totalPages: 0,
  totalElements: 0,
};

export const InitInternWorkList: InternWorkList = {
  works: [],
  totalPages: 0,
  totalElements: 0,
};

export const InitAsmtSearch: AsmtSearch = {
  classification: "",
  status: "",
  year: new Date().getFullYear().toString(),
  searchType: "전체",
  searchWord: "",
  page: 0,
  size: 10,
  isFile: false,
  sort: "createdAt,desc,status,desc",
};

export const InitAsmtDetail: AsmtDetail = {
  id: 0,
  title: "",
  classification: "",
  workType: "일반", //1 => 일반, 2 => 인턴
  participantCnt: 0, //참여자 수
  status: "임시저장", //임시저장, 등록대기, 진행, 완료, 중단
  subStatus: "",
  startDate: "", //yyyy-mm-dd
  createdAt: "", //yyyy-mm-dd hh:mm:ss
  expectedEndDate: "",
  content: defaultValue,
  meritPay: 0, //공개 여부 추가로 인해 수정 예정
  payPeriod: "",
  endDate: "",
  participants: [],
  // articles: [], //추가 예정(2023-01-08까지)
  comments: [],
  attachmentFiles: [],
  setInfo: false,
};

export const InitInternWorkDetail: InternWorkDetail = {
  id: 0,
  title: "",
  classification: "",
  workType: "인턴", //1 => 일반, 2 => 인턴
  participantCnt: 0, //참여자 수
  status: "임시저장", //임시저장, 등록대기, 진행, 완료, 중단
  startDate: moment(new Date()).format("YYYY-MM-DD"), //yyyy-mm-dd
  createdAt: "", //yyyy-mm-dd hh:mm:ss
  content: defaultInternValue,
  endDate: "",
  participants: [],
  interns: [],
  comments: [],
  attachmentFiles: [],
  joinDate: "",
  subStatus: "",
  setInfo: false,
};

export const InitMeetingNoteDetail: MeetingNoteDetail = {
  id: 0,
  title: "",
  parentId: 0,
  meetingDate: "",
  content: "",
  participantCnt: 0, //참여자 수
  participants: [],
  reports: [],
  comments: [],
  attachmentFiles: [],
  setInfo: false,
};

export const InitDevelopNoteDetail: DevelopNoteDetail = {
  id: 0,
  title: "",
  type: "",
  createdAt: "",
  ceoReportStatus: false,
  parentId: 0,
  content: "",
  participantCnt: 0, //참여자 수
  participants: [],
  reports: [],
  comments: [],
  attachmentFiles: [],
  setInfo: false,
};

export const InitReportDetail: ReportDetail = {
  id: 0,
  title: "",
  type: "중간보고",
  createdAt: "",
  ceoReportStatus: false,
  startReportTime: "",
  endReportTime: "",
  parentId: 0,
  parentTitle: "",
  parentParticipants: [],
  workType: "",
  content: "",
  participantCnt: 0,
  participants: [],
  comments: [],
  attachmentFiles: [],
  midRepo: true,
  finRepo: false,
};

export const InitAttachmentFiles: AttachFile = {
  filePath: "",
  filename: "",
};

export const InitWorkUser: WorkUser = {
  id: 0,
  name: "",
  email: "",
  jobTitle: "",
  jobPosition: "",
  group: "",
  team: "",
  department: "",
  resignation: false,
};

export const InitCommentInfo: CommentInfo = {
  type: "",
  targetId: 0,
  depth: 0,
  parentId: null,
  content: "",
  dueDate: "",
  confirm: false,
  examine: false,
  id: 0,
  createdAt: "",
  updatedAt: "",
  user: InitWorkUser,
  children: [],
  sendMail: true,
};

export const InitNotice: Notice = {
  id: 0,
  title: "",
  content: "",
  createdAt: "",
  updated: "",
  writer: InitWorkUser,
  mainNotice: false,
  mainNoticeEndDate: "",
  mainNoticeStartDate: "",
  attachmentFiles: [],
  secret: true,
  setInfo: false,
};

export const InitHr: scheduleConfirm = {
  title: "",
  parentTitle: "",
  content: "",
  type: "",
  startReportTime: "",
  endReportTime: "",
  attendees: [],
  reportTarget: 0,
};

export const InitDaily: dailyWorkWrite = {
  workDate: "",
  issue: "",
  todayWorks: "",
  tomorrowWorks: "",
};

export const InitQna: Qna = {
  articles: [],
  totalPages: 0,
  totalElements: 0,
};

export const InitQnaContent: QnaContent = {
  id: 0,
  title: "",
  depth: null,
  qnaType: "",
  qnaCls: "",
  writer: InitWorkUser,
  createdAt: "",
  content: "",
  parentId: null,
  attachmentFiles: [],
  children: [],
  secret: false,
  setInfo: false,
};

export const InitStateStatistics: AggState = {
  all: 0,
  waitingCount: 0,
  runningCount: 0,
  completionCount: 0,
};

export const InitTriggeringReport: TriggeringReportInfo = {
  title: "",
  content: "",
  reportDate: "",
};

export const InitActionItemInfo: ActionItemInfo = {
  status: "등록대기",
  title: "",
  content: "",
  parentId: null,
  triggeringReportId: null,
  organizationUnitId: null,
  categoryIds: [],
  managers: [],
  priority: "보통",
  isUrgent: false,
  isSecret: false,
  dueDate: "",
};

export const InitBasicInfo: BasicInfo = {
  id: 0,
  name: "",
};

export const InitActionItemStatusInfo: ActionItemStatusInfo = {
  role: "",
  waitingCount: 0,
  completionCount: 0,
  runningCount: 0,
  pendingCount: 0,
  stopCount: 0,
  delayCount: 0,
  tempCount: 0,
};

export const InitActionItemMeetingNoteRequestInfo: ActionItemMeetingNoteRequestInfo =
  {
    title: "",
    content: "",
    isSecret: false,
    participants: [],
    meetingDate: "",
    setInfo: false,
  };

export const InitActionItemReportRequestInfo: ActionItemReportRequestInfo = {
  title: "",
  content: "",
  isSecret: false,
  participants: [],
  reportDate: "",
  isReported: false,
  memo: "",
  setInfo: false,
};
