import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CustomButton,
  PRIMARY,
  SECONDARY,
  StyledBody,
  StyledHead,
} from "../../../styles/theme";
import FileApi from "../../../system/api/fileApi";
import MeetingNoteApi from "../../../system/api/MeetingNoteApi";
import { ErrorHandler } from "../../../system/ApiService";
import { useUserState } from "../../../system/context/UserContext";
import { InitMeetingNoteDetail } from "../../../system/types/initObject";
import { MeetingNoteDetail } from "../../../system/types/MeetingNote";
import {
  AIRoutes,
  AsmtWorkRoutes,
  Ilocation,
  MeetingNoteRoutes,
} from "../../../system/types/type";
import HtmlContent from "../../Common/HtmlContent";
import TitleDiv from "../../Common/TitleDiv";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

interface Params {
  id: string;
}

function MeetingView() {
  const { id } = useParams<Params>();
  const meetingId = parseInt(id);
  const user = useUserState();
  const location = useLocation<Ilocation>();
  const parent = location.state;
  const history = useHistory();
  const [data, setData] = useState<MeetingNoteDetail>(InitMeetingNoteDetail);

  useEffect(() => {
    if (meetingId) {
      MeetingNoteApi.getMeetingNoteDetail(meetingId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      alert("잘못된 접근입니다.");
      history.push(MeetingNoteRoutes.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId]);

  const onUpdatePage = () => {
    history.push({
      pathname: `${MeetingNoteRoutes.root}/edit/${data.id}`,
      state: parent,
    });
  };
  const Loading = useLoadingDispatch();
  const downloadFile = (filename: string, filepath: string) => {
    Loading({ type: "LOADING" });
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("파일 다운로드 중에 오류가 발생했습니다."))
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  const onDeletePage = () => {
    if (window.confirm("미팅 노트를 정말 삭제하시겠습니까?")) {
      MeetingNoteApi.deleteMeetingNote(data.id)
        .then(() => {
          backAsmt();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  };

  const backAsmt = () => {
    if (parent.workType === "일반") {
      return history.push(`${AsmtWorkRoutes.root}/detail/${data.parentId}`);
    } else if (parent.workType === "인턴") {
      return history.push(`${AsmtWorkRoutes.intern}/detail/${data.parentId}`);
    } else if (parent.workType === "액션아이템") {
      return history.push(`${AIRoutes.root}/detail/${data.parentId}`);
    } else {
      return history.push(`${AsmtWorkRoutes.owned}`);
    }
  };

  const onPrintHandler = () => {
    const html = document.querySelector("html");
    const printContents = document.getElementById("download_area")?.innerHTML;
    const printDiv = document.createElement("DIV");

    if (html && printContents) {
      html.appendChild(printDiv);
      printDiv.innerHTML = printContents;
      document.body.style.display = "none";
      window.print();
      document.body.style.display = "block";
      printDiv.style.display = "none";
    }
  };

  const chip = { backgroundColor: SECONDARY, color: "white" };
  const leftBtn = { marginLeft: "10px" };

  if (data) {
    return (
      <>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <TitleDiv title="미팅노트 상세"></TitleDiv>
          </Grid>
          <Grid item xs={2}>
            <CustomButton onClick={backAsmt} iconCss="e-icons e-chevron-left">
              과제페이지
            </CustomButton>
          </Grid>
          <Grid item xs={10} sx={{ textAlign: "right" }}>
            {data.participants.findIndex((x) => x.name === user.name) !== -1 ? (
              <>
                <CustomButton
                  style={{ color: PRIMARY }}
                  iconCss="e-icons e-delete-1"
                  onClick={onDeletePage}
                >
                  삭제
                </CustomButton>
                <CustomButton
                  style={leftBtn}
                  iconCss="e-icons e-edit"
                  onClick={onUpdatePage}
                >
                  수정
                </CustomButton>
              </>
            ) : (
              <></>
            )}
            <CustomButton
              style={leftBtn}
              iconCss="e-icons e-download"
              onClick={onPrintHandler}
            >
              다운로드
            </CustomButton>
          </Grid>
          <Grid item xs={12} id="download_area">
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <StyledHead align="center">과제명</StyledHead>
                    <StyledBody colSpan={5}>{parent.parentTitle}</StyledBody>
                  </TableRow>
                  <TableRow>
                    <StyledHead align="center">미팅주제</StyledHead>
                    <StyledBody colSpan={5}>{data.title}</StyledBody>
                  </TableRow>
                  <TableRow>
                    <StyledHead align="center">참여자</StyledHead>
                    <StyledBody colSpan={5}>
                      {data.participants.map((user, idx) => {
                        return (
                          <Chip key={idx} style={chip} label={user.name} />
                        );
                      })}
                    </StyledBody>
                  </TableRow>
                  <TableRow>
                    <StyledHead align="center">미팅일자</StyledHead>
                    <StyledBody colSpan={5}>
                      {data.meetingDate === ""
                        ? "-"
                        : new Date(data.meetingDate).toLocaleDateString()}
                    </StyledBody>
                  </TableRow>
                  <TableRow>
                    <StyledBody colSpan={6}>
                      <HtmlContent
                        style={{ minHeight: "300px" }}
                        data={
                          data.content
                            ? data.content
                            : "작성된 내용이 없습니다."
                        }
                      ></HtmlContent>
                    </StyledBody>
                  </TableRow>
                  <TableRow>
                    <StyledHead
                      align="center"
                      style={{ padding: "8px", height: "52px" }}
                    >
                      첨부파일
                    </StyledHead>
                    <StyledBody colSpan={5}>
                      {data.attachmentFiles
                        ? data.attachmentFiles.map((file, idx) => {
                            return (
                              <div key={idx} style={{ cursor: "pointer" }}>
                                <Typography
                                  variant="body2"
                                  onClick={() =>
                                    downloadFile(file.filename, file.filePath)
                                  }
                                >
                                  {file.filename}
                                </Typography>
                              </div>
                            );
                          })
                        : "-"}
                    </StyledBody>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <>데이터를 조회할 수 없습니다.</>;
  }
}

export default MeetingView;
